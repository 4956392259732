














import { Component, Prop, Vue } from "vue-property-decorator";
import LoadingSpinner from "@/vue/atoms/LoadingSpinner.vue";

@Component({
  components: { LoadingSpinner }
})
export default class LoadingButton extends Vue {
  @Prop({ default: false }) isLoading!: Boolean;
}
