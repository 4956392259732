



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StatusPanel extends Vue {
  @Prop({ required: true }) private message: string | undefined;
  @Prop({ required: true }) private success: boolean | undefined;
  @Prop({ required: true }) private closeFn!: Function;
}
