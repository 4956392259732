






























export default {
  name: "LoadingSpinner",
  props: {
    size: {
      required: false,
      type: String,
      default: "default"
    },
    color: {
      required: false,
      type: String,
      default: "default"
    }
  }
};
