

















import { Component, Prop, Vue } from "vue-property-decorator";
import { getCurrentPathWithLanguageParameter } from "@/vue/helper/UrlHelper";

@Component({})
export default class LanguageSelection extends Vue {
  @Prop({ required: true }) private languageOptions!: Array<{
    isoCode: string;
    localizedLanguageName: string;
    currentLanguage: string;
  }>;

  //redirect here to same page with different languageN
  selectionChanged(event: any) {
    let modifiedPath = getCurrentPathWithLanguageParameter(
      event.target.value
    );
    window.location.assign(modifiedPath);
  }
}
