import Vue from "vue";
import VueI18n from "vue-i18n";
import { loadLocaleMessages } from "@/vue/services/i18n/i18n-helper";

Vue.use(VueI18n);

declare global {
  interface Window {
    locale: string;
  }
}

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages()
});

export function setLocaleFromGlobal() {
  const locale = window.locale;
  if (locale) {
    i18n.locale = locale;
    i18n.fallbackLocale = locale.substring(0, 2);
  }
}
