import { isDebugJavascript } from "@/vue/helper/JavascriptDebugMarker";
import { CmsRawContent } from "@/vue/services/cms/CmsRawContent";

declare global {
  interface Window {
    cmsContent: {};
  }
}

export class CmsComponent<T extends CmsRawContent | string> {
  private readonly _identifier: string;
  protected _componentContent: T | undefined;

  constructor(identifier: string, component?: T) {
    this._identifier = identifier;

    if (component) {
      this._componentContent = component;
    }
  }

  get identifier(): string {
    return this._identifier;
  }

  protected getDecoratedIdentifier(element: string) {
    return this.identifier + "_" + element;
  }
}

export class CmsContent extends CmsComponent<string> {
  private _acceptEmptyContent: boolean = false;
  private _positionFilled: boolean = false;
  private _ignoreNoPositionFound: boolean = false;

  constructor(position: string, content?: string) {
    super(position, content);
    if (content) {
      this._positionFilled = true;
    }
  }

  set positionFilled(value: boolean) {
    this._positionFilled = value;
  }

  private getContentAsString(): string {
    return typeof this._componentContent === "string"
      ? (this._componentContent as string)
      : "";
  }

  /**
   * formats the content extracted with the methods from @see WithCmsContent
   * 1) if content is NOT empty
   *    a) if no tokens are supplied the base content is returned
   *    b) if tokens are supplied they will be replaced into a not empty content
   * 3) returns empty string if content is empty but position is found and empty content is accepted
   * 4) if content is empty and empty content is NOT accepted
   *    a) returns an empty string if javascript debug is NOT activated
   *    b) returns fallback description string is javascript debug is activated
   *
   * @param tokens to be replaced in the content
   */
  format(...tokens: string[]): string | undefined {
    if (!this._componentContent) {
      let unexpectedEmptyContent = false;
      /* eslint-disable no-console */
      if (this._positionFilled && this._acceptEmptyContent) {
        if (tokens.length) {
          // eslint-disable-next-line no-console
          console.warn(
            'Tokens "%s" for content of position %s cannot be applied since content is empty',
            tokens.toString(),
            this.identifier
          );
        }
        return "";
      } else if (this._positionFilled && !this._acceptEmptyContent) {
        // eslint-disable-next-line no-console
        console.error("No content found for identifier %s", this.identifier);
        unexpectedEmptyContent = true;
      } else if (!this._positionFilled && !this._ignoreNoPositionFound) {
        // eslint-disable-next-line no-console
        console.error("No position named %s found", this.identifier);
        unexpectedEmptyContent = true;
      }
      /* eslint-enable no-console */

      if (unexpectedEmptyContent && isDebugJavascript()) {
        return "{" + this.identifier.replace(/([a-z])([A-Z])/g, "$1 $2") + "}";
      }
      return "";
    }
    return tokens.length
      ? this.getContentAsString().replace(/{(\d+)}/g, function(match, number) {
          return typeof tokens[number] !== "undefined" ? tokens[number] : match;
        })
      : this.getContentAsString();
  }

  isFilled(): boolean {
    return (
      this._positionFilled && typeof this._componentContent !== "undefined"
    );
  }

  /**
   * marks this CmsContent instance to consider empty contents, i.e. empty strings as simple text,
   * captions or urls, as proper content. Empty content will be returned as "" by @see CmsContent#format
   * but @see CmsContent#isFilled will return false independent of this method called or not
   */
  acceptEmptyContent(): CmsContent {
    this._acceptEmptyContent = true;
    return this;
  }

  /**
   * marks this CmsContent instance to ignore missing position scenario. Affects mainly console output when a
   * position is no longer filled.
   */
  ignoreNoPositionFound(): CmsContent {
    this._ignoreNoPositionFound = true;
    return this;
  }
}

export function setCmsContentFromVueRootElement(vueRootElement: HTMLElement) {
  const cmsContent = vueRootElement.getAttribute("data-cms-content");
  if (cmsContent) {
    window.cmsContent = JSON.parse(cmsContent);
    if (!isDebugJavascript()) {
      // Do not show cms position information to user
      vueRootElement.removeAttribute("data-cms-content");
    }
  }
}
