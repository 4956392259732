import "url-search-params-polyfill";

export function getCurrentPathWithLanguageParameter(language: string): string {
  const searchParams = new URLSearchParams(window.location.search);
  return buildPathWithLanguageParameter(
    window.location.pathname,
    language,
    searchParams
  );
}

export function buildPathWithCurrentLanguageParameter(
  relativePath: string,
  queryParamArray?: Record<string, string>
) {
  const queryParam =
    typeof queryParamArray !== undefined
      ? new URLSearchParams(queryParamArray)
      : new URLSearchParams();

  return buildPathWithLanguageParameter(
    relativePath,
    getCurrentLanguageParameter(),
    queryParam
  );
}

function getCurrentLanguageParameter(): string | null {
  return new URLSearchParams(window.location.search).get("l");
}

function buildPathWithLanguageParameter(
  relativePath: string,
  language: string | null,
  queryParam: URLSearchParams
) {
  if (language !== null) {
    queryParam.set("l", language);
  }

  if (queryParam.toString().length) {
    relativePath = relativePath + "?" + queryParam.toString();
  }
  return relativePath;
}
