





























import { Component, Prop, Vue } from "vue-property-decorator";

const phoneBreakpoint = 745;
const handheldsBreakpoint = 960;
const unknown = "UNKNOWN";

@Component
export default class IronDoorStickyHeader extends Vue {
  @Prop({
    required: true
  })
  mounted() {}
  @Prop({ required: false, default: "false" }) private useNewBSLogo?: boolean;
}
