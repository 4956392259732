import Vue from "vue";
import Vuelidate from "vuelidate";
import VueScrollTo from "vue-scrollto";
import { i18n, setLocaleFromGlobal } from "@/vue/services/i18n/i18n";
import { setCmsContentFromVueRootElement } from "@/vue/services/cms/CmsContent";
import { setDebugJavascriptInRoot } from "@/vue/helper/JavascriptDebugMarker";

import "@/scss/init_bestsecret/entrance.scss";

import LanguageSelection from "@/vue/molecules/LanguageSelection.vue";
import IronDoorEntranceComponent from "@/vue/organisms/entrance/common/IronDoorEntranceComponent.vue";
import IronDoorApplyMembership from "@/vue/organisms/entrance/common/IronDoorApplyMembership.vue";

Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueScrollTo);

setLocaleFromGlobal();

const rootElement = document.getElementById("vue-entrance-main")!;
setDebugJavascriptInRoot(rootElement);
setCmsContentFromVueRootElement(rootElement);

new Vue({
  i18n,
  validations: {},
  components: {
    "iron-door-entrance-component": IronDoorEntranceComponent,
    "iron-door-apply-membership": IronDoorApplyMembership,
    "language-selection": LanguageSelection
  }
}).$mount("#vue-entrance-main");

new Vue({
  i18n,
  components: {
    "language-selection": LanguageSelection
  }
}).$mount("#vue-entrance-footer");
