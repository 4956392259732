






























import axios from "axios";
import { Component, Mixins, Prop } from "vue-property-decorator";
import WithCmsContent from "@/vue/mixins/WithCmsContent";
import StatusPanel from "@/vue/molecules/StatusPanel.vue";
import LoadingButton from "@/vue/atoms/LoadingButton.vue";
import WithErrorEvents from "@/vue/mixins/WithErrorEvents";

import { pushToGTM } from "@/vue/services/tracking/gtm-helpers";

@Component({
  components: { StatusPanel, LoadingButton }
})
export default class IronDoorUniversalLoginForm extends Mixins(
  WithCmsContent,
  WithErrorEvents
) {
  @Prop({ default: "" }) private doubleClickPreventor!: string;
  @Prop() private authorizeUrl: any;
  @Prop() private statusMessage?: string;
  @Prop() private statusSuccess?: boolean;
  @Prop({ default: "" }) private locale!: string;
  @Prop({ default: "" }) private cloudflareCountry!: string;
  @Prop({ required: false, default: false })
  private rememberMeToken!: boolean;
  @Prop() private processingHint!: string;
  @Prop() private registrationLinkText?: string;
  @Prop({ required: false, default: "" }) private savedRequestUrl!: string;

  private submitInProgress: boolean = false;

  created() {
    window.addEventListener("pageshow", () => {
      this.submitInProgress = false;
    });
  }

  submitLoginWithGaEvent(e: Event) {
    e.preventDefault();
    this.submitInProgress = true;

    pushToGTM([{ event: "click_login", login_method: undefined }]);

    const redirectWaitTimeMs = 350;
    axios
      .get(this.authorizeUrl, { params: this.parametersForGetAuthorizeUrl() })
      .then(response => {
        if (response.status === 200 && response.data.location) {
          // redirection is delayed to allow GTM events to be handled
          setTimeout(_ => {
            window.location.href = response.data.location;
          }, redirectWaitTimeMs);
        }
      })
      .catch(_ => {
        this.statusMessage = this.$t(
          "message.technicalIssuesErrorMessage"
        ).toString();
        this.statusSuccess = false;
        this.submitInProgress = false;
      });
  }

  closeErrorPanel() {
    this.statusMessage = undefined;
    this.statusSuccess = undefined;
  }

  private parametersForGetAuthorizeUrl() {
    const parameters = {} as any;
    if (this.locale && this.locale !== "") {
      parameters.lang = this.locale;
    }
    if (this.savedRequestUrl && this.savedRequestUrl !== "") {
      parameters.savedRequest = this.savedRequestUrl;
    }
    if (this.cloudflareCountry && this.cloudflareCountry !== "") {
      parameters.country = this.cloudflareCountry;
    }
    return parameters;
  }
}
