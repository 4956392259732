declare global {
  interface Window {
    dataLayer: {}[];
    localDataLayer: {}[];
  }
}

export interface GtmEvent {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventSegment: string;
  eventValue: string;
}

export interface Ga4LoginEvent {
  event: string;
  // undefined will cause the key not to appear in the data layer object and can be used to omit the field:
  login_method: string | undefined;
}

export function pushToGTM(events: GtmEvent[] | Ga4LoginEvent[]) {
  if (!window.localDataLayer) {
    window.localDataLayer = [];

    window.addEventListener(
      "gtm-consent-status-ready",
      function() {
        window.localDataLayer.forEach(function(event) {
          window.dataLayer.push(event);
        });

        window.localDataLayer = window.dataLayer; // from now on, pushes to localDataLayer go to the actual dataLayer
      },
      { once: true }
    );
  }

  for (const event of events) {
    window.localDataLayer.push(event);
  }
}
