declare global {
  interface Window {
    debugJavascript: boolean;
    debugCmsRealistic: boolean;
  }
}

export function isDebugJavascript(): boolean {
  return window.debugJavascript || false;
}

export function setDebugJavascriptInRoot(vueRootElement: HTMLElement) {
  const debugJavascript = vueRootElement.getAttribute("data-debug") === "true";
  if (debugJavascript) {
    window.debugJavascript = debugJavascript;
  }
}
