import { Component, Vue } from "vue-property-decorator";
import { CmsComponent, CmsContent } from "@/vue/services/cms/CmsContent";
import { CmsRawContent } from "@/vue/services/cms/CmsRawContent";

const firstEntryPlain = (positionContent: Array<any>) => positionContent[0];
const firstEntryAsImageUrl = (positionContent: Array<any>) =>
  positionContent[0] ? positionContent[0].imageUrl : undefined;
const firstEntryAsCaption = (positionContent: Array<any>) =>
  positionContent[0] ? positionContent[0].caption : undefined;

@Component
export default class WithCmsContent extends Vue {
  cmsContent: any = window.cmsContent || {};

  cmsPositionAsArray(position: string): Array<any> {
    return this.cmsContent[position]
      ? Array.from(this.cmsContent[position])
      : new Array<String>();
  }

  cmsPositionAsComponentArray<T extends CmsComponent<CmsRawContent>>(
    position: string,
    classType: new (identifier: string, content: CmsRawContent) => T
  ) {
    const content = this.cmsContent[position];

    return content
      ? Array.from(content).map(
          (cmsContentElement: any, index: number) =>
            new classType(
              position + "_[" + index.toString() + "]",
              cmsContentElement
            )
        )
      : new Array<T>();
  }

  //fetches content from given position
  cmsTextPosition(position: string): CmsContent {
    return this.extractCmsContent(
      position,
      firstEntryPlain
    ).acceptEmptyContent();
  }

  cmsImageWithCaptionPositionCaption(position: string): CmsContent {
    return this.extractCmsContent(
      position,
      firstEntryAsCaption
    ).acceptEmptyContent();
  }

  cmsImageWithCaptionPositionImageUrl(position: string): CmsContent {
    return this.extractCmsContent(position, firstEntryAsImageUrl);
  }

  cmsImagePositionImageUrl(position: string): CmsContent {
    return this.extractCmsContent(position, firstEntryPlain);
  }

  private extractCmsContent(
    position: string,
    extractor: (cmsArray: Array<any>) => string
  ): CmsContent {
    const positionContent = this.cmsContent[position];
    if (Array.isArray(positionContent) && positionContent.length) {
      const content = extractor(positionContent);
      const cmsContent = new CmsContent(position, content);
      cmsContent.positionFilled = true;
      return cmsContent;
    }
    return new CmsContent(position, undefined);
  }
}
