
import { Vue, Component, Prop } from "vue-property-decorator";
import LoadingButton from "@/vue/atoms/LoadingButton.vue";
import IronDoorUniversalLoginForm from "@/vue/organisms/entrance/login/IronDoorUniversalLoginForm.vue";

const phoneBreakpoint = 767;

@Component({
  components: { IronDoorUniversalLoginForm, LoadingButton }
})
export default class IronDoorHero extends Vue {
  // Login
  @Prop() private locale?: string;
  @Prop() private entranceStatusMessage?: string;
  @Prop() private entranceStatusSuccess?: boolean;
  @Prop() private cloudflareCountry?: string;
  @Prop() private authorizeUrl: any;
  @Prop({ required: false, default: "" }) private savedRequestUrl!: string;
  @Prop({ required: false, default: false })
  private rememberMeToken!: boolean;
  @Prop() private loginDoubleClickPreventor!: string;
  @Prop() private registrationLinkText?: string;
  @Prop() private processingHint!: string;
  @Prop({ required: false, default: "false" }) private useNewBSLogo?: boolean;

  private videoKey = 0;
  private videoOrientation: "portrait" | "landscape" = "portrait";

  mounted() {
    this.enforceAutoPlayVideo();

    this.setVideoOrientation();
    window.addEventListener("resize", this.forceVideoReRender);
  }

  destroyed() {
    window.removeEventListener("resize", this.forceVideoReRender);
  }

  setVideoOrientation() {
    window.innerWidth < 992
      ? (this.videoOrientation = "portrait")
      : (this.videoOrientation = "landscape");
  }

  forceVideoReRender() {
    if (window.innerWidth < 992 && this.videoOrientation === "landscape") {
      this.videoOrientation = "portrait";
      this.videoKey++;
    } else if (
      window.innerWidth > 992 &&
      this.videoOrientation === "portrait"
    ) {
      this.videoOrientation = "landscape";
      this.videoKey++;
    }
  }

  get currentComponentProps() {
    return {
      authorizeUrl: this.authorizeUrl,
      doubleClickPreventor: this.loginDoubleClickPreventor,
      rememberMeToken: this.rememberMeToken,
      processingHint: this.processingHint,
      entranceStatusMessage: this.entranceStatusMessage,
      entranceStatusSuccess: this.entranceStatusSuccess,
      registrationLinkText: this.registrationLinkText,
      locale: this.locale,
      cloudflareCountry: this.cloudflareCountry,
      savedRequestUrl: this.savedRequestUrl
    };
  }

  isMobile() {
    return window.innerWidth < phoneBreakpoint;
  }

  enforceAutoPlayVideo() {
    const videoElements = document.getElementsByTagName("video");
    for (let video of videoElements) {
      video.controls = false;
      video.play();
    }
  }

  heroLoginButtonClicked() {
    this.$emit("hero-button-clicked");
  }
}
